<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import liveConfigInfo from '@/config/liveConfigInfo';

export default {
  name: 'app',
  mixins: [liveConfigInfo],
  created() {
     this.getLiveTencentGetTxConfigSession();
  },
  mounted(){
    // 关闭浏览器窗口的时候浏览器缓存" title="清空浏览器缓存">清空浏览器缓存在localStorage的数据
    window.onbeforeunload = function (e) {
      // console.log('清空缓存了')
      // var storage = window.localStorage;
      // storage.clear()
    }
  },

};
</script>
<style lang="less">
@import "./assets/less/style.less";
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  font-size: 16px;
  background-size: cover;
  user-select:text;
}

</style>
