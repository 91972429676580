 import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import 'material-design-icons/iconfont/material-icons.css';
// import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';

// Vue.use(Vuetify);

// export default new Vuetify({
//     icons: {
//         iconfont: 'mdi', // 默认值 - 仅用于展示目的
//     },
// });

// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {}

export default new Vuetify(opts)



