/*
 * @Description: vuex-state
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2022-02-15 18:04:25
 */
import { LIVE_STAGE, LINE_TYPE, LAYOUT } from '@/constants/room';
import { GET_LIVE_TENCENT_CONFIG } from '@/constants/mutation-types';


import Config from '../config/index';

export default {
  // 直播间阶段
  liveStage: LIVE_STAGE.NOT_STARTED,
  sdkAppId: "",
  //是否填下资料了
  showFilter:0,
  // 房间号信息
  roomInfo:{
    roomId: null,
    // 直播间昵称
    roomName: '',
    bgImg:"",
    logoCover:"",
    //限制类型：no_filter-没有限制； register_filter-登记观看 visitor_filter-游客观看
    watchFilter:"",
    //房间状态：no_live-no_live-未开播 live_ing-直播中 forbidden-房间封禁 live_pause-直播暂停 live_over-直播结束
    roomStatus:"",
    //直播间类型： meeting-多人会议； live-单人直播； record-录播
    roomType:'',
    startTime:'',
    lineType:"",
    //游客观看开关限制：switch_close-关闭； switch_open-开启
    visitorSwitch:'',
    hotScores:null,
  },
  // 播放域名
  playerDomain: '',
  // 主播用户信息
  anchorUserInfo: {
    userId: '', // 用户ID
    userName: '', // 用户昵称
    userAvatar: '', // 用户头像
  },
  //监听点击全屏
  allScreen:false,
  //第三id
  thirdUserId:"",
  //回放视频
  videoUrl:"",
  //是否放弃投票
  switchButtom:true,
  //是否开启投票
  switchVote:false,
  //投票信息
  voteList:[],
  //房间菜单栏列表
  roomlist:[],
  // 用户信息
  userInfo: {
    userId: '',
    imUserId: '',
    userName: '',
    userAvatar: '',
    shareUserId:"",
    userSig: '',
    shareUserSig: '',
    //用户角色
    userRole:'',
  },
  // 是否支持 webRTC
  isSupportWebRTC: true,
  // 线路选择-rtc|cdn|leb
  lineType: LINE_TYPE.CDN,
  // 播放状态-playing|paused
  playState: 'paused',
  // horizontal｜vertical
  layout: LAYOUT.VERTICAL,
  // 是否在屏幕分享中
  isScreenSharing: false,
  // 使用的摄像头设备
  activeCamera: {},
  // 使用的麦克风设备
  activeMicrophone: {},
  // 使用的扬声器设备
  activeSpeaker: {},
  // 本地流是否设置镜像
  isSetMirror: true,
  // 视频参数
  videoProfile: {
    width: 1920,
    height: 880,
    frameRate: 25,
    bitrate: 500,
  },
  // 是否开启美颜
  isOpenBeauty: false,
  // 美颜参数
  beautyParam: {
    beautyValue: 50,
    brightnessValue: 50,
    ruddyValue: 50,
  },
  // 屏幕分享参数
  screenProfile: {
    width: 1920,
    height: 880,
    frameRate: 15,
    bitrate: 800,
  },
  // 是否开启录制
  isRecordLive: true,
  // 音频采集状态
  isAudioMuted: false,
  // 视频采集状态
  isVideoMuted: false,
  // 音量大小
  audioLevel: 100,
  // 上行网络质量等级
  uplinkQualityLevel: 0,

  // 观看人员列表
  memberList: [],


  isSignalReady: false,
    isTiwReady: false, // 白板是否已经Ready
    classInfo: {
      sdkAppId: Config.sdkAppId,
      userId: sessionStorage.getItem('TIW_CLASSINFO_USERID'),
      userSig: sessionStorage.getItem('TIW_CLASSINFO_USERSIG'),
      classId: sessionStorage.getItem('TIW_CLASSINFO_CLASSID'),
      nickname: sessionStorage.getItem('TIW_CLASSINFO_NICKNAME'),
    },
    current: {
      toolType: 0,
      fileInfo: {},
    },

    boardSetting: {

    },

    rightBarShow: false,
};
