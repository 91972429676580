<!--
 * @Description: 移动端 IM 即时通讯组件
 * @Date: 2022-01-25 16:05:22
 * @LastEditTime: 2022-02-21 15:22:10
-->
<template lang="pug">
div.im-message-container
  //- 聊天区域
  div.hot(style={display: 'flex',alignItems:'center'})
              img.imgs(src="../../../assets/img/hot.gif", alt="")
              span(style={marginLeft:'5px'}) {{hotScores}}
  div.content-top-chat
   
    div.message-container(ref="box")
      
      div.single-message-h5(v-for="message, index in messageList")
        div.message-content-container(:class="isMobileHorizontalLayout ? 'dark-background' : ''")
          img.messgae-avatar(src="../../../assets/img/avatar.png")
          span.user-name(:class="isAnchorMessage(message) && 'anchor-name'") {{ getUserNick(message) + ':' }}
          span.message-content(v-for="item, index in message.renderContent")
            //- 文字消息
            span(
              v-if="item.name === 'text'"
              :key="index"
            ) {{item.content }}
            //- 表情消息
            img.message-icon(
              v-else-if="item.name === 'img'"
              :src="item.src"
              :key="index"
            )
        div.message-send-fail(v-if="message.state === 'fail'" @click="sendMessage(message.content)")
          svg-icon.icon-style(icon-name="error")

  //- 文字及表情输入区域
  div.send-message-container
    //- 表情选择框
    div.emojis-popover(ref="emojiPopover" v-show="showEmojiPopover")
      div.emojis
        div.emoji(
          v-for="emoji in emojiName"
          :key="emoji"
          @click="chooseEmoji(emoji)"
        )
          img(:src="emojiUrl + emojiMap[emoji]")
      div.emojis-popover-arrow
    div.content-bottom
      //- 表情icon
      span.icon-button(ref="emojiIcon" @click="toggleShowEmojis")
        //- svg-icon.emoji-icon(icon-name="emoji")
      //- 文字输入框
      div.content-bottom-input
        input.input(
          ref="input"
          type="text"
          v-model="inputMsg"
          :placeholder="inputPlaceHolder"
          @keyup.enter="handleSendMsg"
          @touchmove.prevent
          :disabled="isLiveEnded || showLoginAttention "
          required="required"
        )
      //- 发送按钮
      div.send-btn(@click="handleSendMsg" v-if="!showLoginAttention") 发送

      div.login-attention(v-if="showLoginAttention")
        span {{ $t('Current visitor status') }}
        a(@click="handleLogin") {{ $t('login') }}
</template>

<script>
import tim from './tim.js';
import { mapState ,mapGetters} from 'vuex';
export default {
  name: 'imMobile',
  mixins: [tim],
  props: {
    isMobileVerticalLayout: {
      type: Boolean,
    },
    isMobileHorizontalLayout: {
      type: Boolean,
    },
  },
  data() {
    return {
      showEmojiPopover: false,
      isCanScroll: false,
      hotScores:''
    };
  },
  computed: {
    ...mapState({
      roomInfo: 'roomInfo',
      userInfo: 'userInfo',
    }),
    ...mapGetters(["get_hotOnline"]),
  },
  watch:{
    get_hotOnline(data){
      console.log('热度',data);
      let arr={}
     for(let i of data){
        arr=i
     }
     this.hotScores=arr.hotScores
    }
   },
  created(){
    this.hotScores=this.roomInfo.hotScores
  },
  mounted () {
    this.$eventBus.$off("timGroupBack"); 
    this.$EventBus.$on("timGroupBack", (data) => {
        this.quitGroupBack(data)
        // this.logout()
    });
    this.$eventBus.$off("timJoinGroup"); 
    this.$EventBus.$on("timJoinGroup",async (msg) => {
       this.joinGroup()
      
       
    });
    // this.$refs.box.addEventListener('scroll', this.onScroll);
    document.body.addEventListener('touchmove',this.bodyScroll,{passive: false});
    this.$nextTick(() => {
      // this.wrapperHeight = window.innerHeight - utils.computeRealPx(98);
    });
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // 如果是IOS设备
    if (isiOS) {
      let flag = false
      let pageBackNormFunc
      // 聚焦后，键盘弹起
      document.body.addEventListener('focusin', () => {
        flag = true
        pageBackNormFunc && clearTimeout(pageBackNormFunc)
      })
      // 失焦后，键盘关闭
      document.body.addEventListener('focusout', () => {
        if (flag) {
          // 页面滚动回原来的位置
          pageBackNormFunc = setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }, 200)
        }
        flag = false
      })
    }
  },
  methods: {
    toggleShowEmojis() {
      this.showEmojiPopover = !this.showEmojiPopover;
    },
    handleHideEmojiPopover(event) {
      if (!this.$refs.emojiIcon.contains(event.target) && !this.$refs.emojiPopover.contains(event.target)) {
        this.showEmojiPopover = false;
      }
    },
  },
  bodyScroll(event) {
    event.preventDefault();
  },
  onBlurInput () {
    window.scroll(0, 0)
  },
  onScroll () { //onScroll方法放在methods:{}中
    let nScrollHeight = this.$refs.box.scrollHeight; // 滚动距离总长 内部div的高度
    let nScrollTop = this.$refs.box.scrollTop; // 滚动条（点）当前的位置在内部div的高度 里占多少
    let itemHeight = nScrollHeight / (this.items.length); // 可以理解为每一个item的高度
    let scrollLength = itemHeight * (this.items.length - 4); // 默认显示4个item
    this.bottomMaskShow = true;
    if (nScrollTop > (scrollLength -49)) { // 49 这个差值比较灵活，是根据项目中打印出来的item高度和scrollTop实际滚动（出去）的值对比得出的
      this.bottomMaskShow = false;
    } else
    if(nScrollTop <= (scrollLength -49)){
      this.bottomMaskShow = true;
    }
  },
  changeBlur(){
    let u = navigator.userAgent, app = navigator.appVersion;
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if(isIOS){
      setTimeout(() => {
        const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0
        window.scrollTo(0, Math.max(scrollHeight - 1, 0))
      }, 200)
    }
  },
  //禁止滚动
  stopScroll(){
    var mo=function(e){e.preventDefault();};
    document.body.style.overflow='hidden';
    document.addEventListener("touchmove",mo,false);//禁止页面滑动
  },
  /***取消滑动限制***/
  canScroll(){
    var mo=function(e){e.preventDefault();};
    document.body.style.overflow='';//出现滚动条
    document.removeEventListener("touchmove",mo,false);
  },
  handleFocus() {
    alert(11)
      this.$refs.botFooter.style.bottom = window.innerHeight + 'px'
      setTimeout(() => this.reset(), 100)
  },
  reset() {
    document.body.style.height = window.innerHeight + 'px'
    this.$refs.botFooter.style.bottom = 0
  },
  beforeCreate() {
    document.addEventListener('click', event => this.handleHideEmojiPopover(event));
  },
  beforeDestroy() {
    document.removeEventListener('click', event => this.handleHideEmojiPopover(event));
  }
};
</script>


<style lang="less" scoped>
  @media screen and (min-width: 900px){
    .content-bottom{
        width:60%;
        min-width:217px;
        max-width: 320px;
        height: 32px;
        border-radius :16px;
        background-color: #fff;
        display :flex;
        align-items: center;
        border: 1px solid rgba(255,255,255,0.20);
        padding: 0 2px 0 6px;
        margin-left: 15px;
        pointer-events :all;
        position :relative
      }
  }
  @media screen and (max-width: 900px){
    .content-bottom{
          width:90%;
          min-width:217px;
          max-width: 25rem;
          height: 32px;
          border-radius :7px;
          background-color: #fff;
          display :flex;
          align-items: center;
          border: 1px solid rgb(32, 31, 31);
          padding: 0 0 0 6px;
          margin: 0 auto;
          pointer-events :all;
          position :relative
    }
    .mobile-horizontal-layout{
      .content-bottom{
        width:60%;
        min-width:217px;
        max-width: 320px;
        height: 32px;
        border-radius :16px;
        background-color: #fff;
        //display :flex;
        display: none !important;
        align-items: center;
        border: 1px solid rgba(255,255,255,0.20);
        padding: 0 2px 0 6px;
        margin-left: 15px;
        pointer-events :all;
        position :relative;
        .input{
          color: #8a9099;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          font-size: 14px;
          border: none;
          outline: none;
          resize: none;
        }
        .send-btn{
          padding: 2px 4px !important;
          background-color: #006eff;
          width: 50px !important;
          border-radius: 16px !important;
          height: 26px !important;
          line-height: 24px !important;
          font-size: 14px !important;
        }
      }
    }
  }
  @media screen and (max-width: 900px){
    .message-content{
      color:#000;
    }
    .mobile-horizontal-layout{
      .message-content{
        color: #fbfdff !important;
      }
    }
  }
  @media screen and (min-width: 900px){
    .mobile-vertical-layout{
      .message-container{
        position: absolute;
        bottom: 0;
        width :calc(100% + 10px);
        max-height: 100%;
        overflow :auto;
      }
    }

  }
  @media screen and (max-width: 900px){
    .mobile-vertical-layout{
      .message-container{
        position: absolute;
        top:0.5rem;
        width :calc(100% + 10px);
        max-height: 100%;
        overflow :auto;
        height: 47%;
      }
    }

  }
  
  .mobile-horizontal-layout{
    .im-message-container{
         display: none;
    }
  }
  

  
  @media screen and (max-width: 600px){
    .mobile-horizontal-layout {

      .im-message-container {
        .content-top-chat {
          display: none !important;
          .message-container {
            position: absolute;
            bottom: 0;
            width: calc(100% + 10px);
            max-height: 100%;
            overflow: auto;

            .single-message-h5 .message-content-container.dark-background {
              background: none !important;
            }
          }

          .single-message {
            .message-content-container {
              display: flex;
              align-items: center;

              .message-content {
                width: 80%;
                min-width: 260px;
                background-color: rgba(223, 223, 223, 0.05);
                padding: 8px 12px;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 500;
                word-break: break-all;
              }

              .message-content span {
                display: inline-block;
                vertical-align: center;
              }

              .message-content span .message-icon {
                width: 20px;
                height: 20px;
                vertical-align: middle;
              }

              .message-send-fail {
                width: 24px;
                height: 24px;
                margin-left: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .mobile-vertical-layout{
      .message-container{
        position: absolute;
        top:18.5rem;
        width :calc(100% + 10px);
        max-height: 100%;
        overflow :scroll;
        height: 36%;


        //height calc(100% - 18px)
      }
    }

  }
.hot{
  position: fixed;
  top: 23rem;
  right: 1rem;
}
.imgs{
  width:20px;
}
</style>

<style lang="stylus" scoped>
.im-message-container
  width 100%
  height calc(100% - 52px)
  .content-top-chat
    position relative
    width 320px
    
    margin 10px 0 8px 18px
    height 68rem
    border-radius 10px
    color $fontColor
    font-size 14px
    overflow hidden
    pointer-events all
    .message-container

      .single-message-h5
        width 100%
        text-align left
        align-items center
        display flex
        margin-top 5px
        .message-content-container
          align-items center
          background-color rgba(223,223,223,0.05)
          padding 3px 12px
          border-radius 12px
          line-height 18px
          max-width calc(100% - 10px)
          display flex
          &.dark-background
            background-color rgba(0,0,0,0.35)
          .user-name
            display inline-block
            margin-right 12px
            color #000
            &.anchor-name
              color #FFBE8A
          .message-content
            border-radius 4px
            font-size 14px
            word-break break-all
            vertical-align center
            .message-icon
              width 20px
              height 20px
              vertical-align middle
        .message-send-fail
          width 24px
          height 24px
          margin-left 10px
          cursor pointer
          display flex
          align-items center
  .send-message-container
    position fixed
    bottom 5px
    z-index 10
    width 100%
    .emojis-popover
      position absolute
      background #FFF
      min-width 150px
      max-width 400px
      width calc(100% - 8px)
      border-radius 4px
      border 1px solid #EBEEF5
      padding 12px
      color #606266
      line-height 1.4
      text-align justify
      font-size 14px
      box-shadow 0 2px 12px 0 rgb(0 0 0 / 10%)
      word-break break-all
      transform translateX(8px) translateY(-100%) translateY(-8px)
      pointer-events all
      .emojis
        height 170px
        max-width 400px
        overflow scroll
        .emoji
          height 30px
          width 30px
          float left
          box-sizing border-box
          img
            width 30px
            height 30px
      &::after
        content ''
        width 0
        height 0
        border 6px transparent solid
        border-top-color #FFF
        position absolute
        left 18px
        top 100%
    .content-bottom

      span.icon-button
        cursor pointer
        display flex
        .emoji-icon
          width 20px
          height 20px
          fill $fontColor
      div.content-bottom-input
        text-align left
        position relative
        margin 0 6px
        flex-grow 1
        .input
          color $grayFontColor
          top 0
          right 0
          width 100%
          height 100%
          background-color $IMThemeColor
          font-size 14px
          border none
          outline none
          resize none
      .send-btn
        padding 2px 4px
        background-color $highLightColor
        width 50px
        border-radius 7px
        height 32px
        line-height 24px
        font-size 14px
        color #fff
      .login-attention
        position absolute
        left 40px
        font-size 14px
        color $grayFontColor
        line-height 32px
.messgae-avatar
   width 25px
   height 25px
</style>

<i18n>
{
	"en": {
		"Chat now": "Chat now！",
    "Type a message": "Type a message",
    "Current visitor status": "Visitor status, please ",
    "login": "login",
    "Send": "Send",
    "Live room does not exist": "Live admin does not exist"
	},
	"zh": {
		"Chat now": "快来互动吧！",
    "Type a message": "参与互动",
    "Current visitor status": "当前为游客身份，请先",
    "login": "登录",
    "Send": "发送",
    "Live room does not exist": "直播间不存在"
	}
}
</i18n>
