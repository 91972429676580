import '@/assets/style/global.css';
import Vue from 'vue';
import App from '@/App.vue';
import eventBus from '@/putils/_eventBus';
import store from '@/stores';
import router from '@/router/index';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist'
import VueBus from 'vue-bus';

import vuetify from './plugins/vuetify';
 Vue.prototype.TEduBoard =window.TEduBoard






import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview,{
  fullscreenEl: false, //控制是否显示右上角全屏按钮
  closeEl: true, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: false, //控制是否显示放大缩小按钮
  counterEl: false, //控制是否显示左上角图片数量按钮
  arrowEl: false,  //控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: false, //点击应切换控件的可见性
  clickToCloseNonZoomable: false //点击图片应关闭图库，仅当图像小于视口的大小时
})


import ElementUI,{
  Button,
  Select,
  Dialog,
  Input,
  Slider,
  Message,
  Card,
  Popover,
  Avatar,   
  Tabs,
  TabPane,
  Option,
  Checkbox,
  InputNumber,
  MessageBox,
  Tooltip,
  Radio,} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/icons';
import i18n from '@/locales/i18n';
import { isMobile } from '@/putils/utils.js';
import VueClipboard  from 'vue-clipboard2'
import EventBus from '@/putils/EventBus'

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '2pZWnYXAmFykDpxgAVTqs1BNb2YaKYXH'
})
// import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.prototype.$EventBus = EventBus
/**
 * 移动端调试
 */
//import VConsole from "vconsole";
//let vconsole:any = new VConsole()
//Vue.use(vconsole)

document.title = <string>i18n.t('title.player');


// 输入框禁止输入特殊字符和中文
Vue.prototype.forbid_symbol_chinese = function (value:any) {
  value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/[\u4E00-\u9FA5]/g, '').replace(/\s/g, '');
  return value;
}

// 输入框禁止输入特殊字符
Vue.prototype.forbid_symbol = function (value:any) {
  value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '');
  return value;
}

// 输入框禁止输入中文和空格
Vue.prototype.forbid_chinese = function (value:any) {
  value = value.replace(/[\u4E00-\u9FA5]/g, '').replace(/\s/g, '');
  return value;
}

// 输入框仅能输入number,禁止输入小数点
Vue.prototype.permit_number = function (value:any) {
  value = value
    .replace(/^\.+|[^\d.]/g, '')
    .replace(/[.]/g, '').replace(/\s/g, '')
  return value;
}
// 输入框仅能输入正数，小数
Vue.prototype.permit_money = function (value:any) {
  var value = value
    .replace(/[^0-9.]/g, '')
    .replace(/^\.\d{1,2}/g, '')
    .replace('.', 'dollar#dollar')
    .replace(/\./g, '')
    .replace('dollar#dollar', '.')
  return value;
}

// 输入框仅能输入正数，负数，小数
Vue.prototype.permit_money2 = function (value:any) {
  var t = value.charAt(0);
  value = value
    .replace(/[^0-9.]/g, '')
    .replace(/^\./g, '')
    .replace('.', 'dollar#dollar')
    .replace(/\./g, '')
    .replace('dollar#dollar', '.')

  if (t == '-') {
    value = '-' + value;
  }
  return value;
}

//弹出框禁止滑动
Vue.prototype.stopScroll = function () {
  var mo = function (e:any) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}

//弹出框可以滑动
Vue.prototype.canScroll = function () {
  var mo = function (e:any) {
    e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
}

/**
 *  重写ElementUI的Message
 */
let messageInstance : any;
const donMessage = (options:any) => {
  if(messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
["error","success","info","warning"].forEach(type => {
  // @ts-ignore
  donMessage[type] = (options:any) => {
    if (typeof options === "string") {
      options = {
        message: options
      };
    }
    options.type = type;
    return donMessage(options);
  };
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersist);
Vue.use(ElementUI);

Vue.use(Button);
Vue.use(Select);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Slider);
Vue.use(Input);
Vue.use(Card);
Vue.use(Popover);
Vue.use(Avatar);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(InputNumber);
Vue.use(Tooltip);
Vue.use(Radio);
Vue.use(VueClipboard);


Vue.use(VueBus);
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = donMessage;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$isMobile = isMobile();
Vue.config.productionTip = false;



new Vue({
  pinia,
  i18n,
  store,
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app');
